import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.min.js'
// require("https://cdn.jsdelivr.net/npm/katex@0.16.8/dist/contrib/auto-render.min.js")
// require("prismjs/themes/prism.css")

require(`katex/dist/katex.min.css`)
require("prismjs/themes/prism-okaidia.css")

export const onServiceWorkerUpdateReady = () => {
    const answer = window.confirm(
      `This application has been updated. ` +
        `Reload to display the latest version?`
    )
  
    if (answer === true) {
      window.location.reload()
    }
}
  
export const registerServiceWorker = () => true
  